import React, {useEffect, useState} from 'react';
import '../reception/reception-block.css'
import WebApi from '../../helpers/WebApi';
import Buttons from '../buttons/buttons';
import { useSnackbar } from '../../notification/index';


function ReseptionServicesBlock({ data, id, name, parentId, onClick, i, numbers}) {

  const { enqueueSnackbar } = useSnackbar();

  if (!numbers) numbers= i; else numbers = numbers + '.' + i;

  let subServices = data.filter((el) => { return el.parentId == id;});

  const [activeServices, setActiveServices] = useState(false)

  function OpenServices() {
      setActiveServices(!activeServices);
  }

  return (
    <div className="reception-services__content">
        <div className="reception-services__block-before" onClick={subServices.length ? OpenServices : onClick}><div className="reception-services__block">
            <span id={id}>
                {numbers}. {name}
            </span>
            {subServices.length ? (<span className="icon icon_s icon_background-auto icon_services-accardion" style={activeServices ? {} : {transform: 'rotate(180deg)'}} ></span>) : (<></>)}
        </div></div>
        {subServices.length ? (<>
        <div className={"reception-services__sub-services" + `${activeServices == true ? " active" : ""}`}>
            {subServices.map((el,i) => <React.Fragment key={i+10}>
              <ReseptionServicesBlock numbers={numbers} key={el} i={i+1} data={data} id={el.id} name={el.name} parentId={el.parentId} onClick={onClick} />
            </React.Fragment>)}  
        </div>
        </>) : (<></>)} 
    </div>
  )

}

export default ReseptionServicesBlock;

/*

*/