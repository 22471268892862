import React, {useEffect, useState} from 'react';
import '../reception/reception-block.css'
import WebApi from '../../helpers/WebApi';
import Buttons from '../buttons/buttons';
import { useSnackbar } from '../../notification/index';


function ReceptionBranch({ active, data, onClick }) {

  const { enqueueSnackbar } = useSnackbar();
 
  return (
    <div className={"reception-branch"}>
       {data !== 'error' && data.length > 1  && <> {data.map((branch, i) =>
        <Buttons key={i} id={branch.id} dataAdress={branch.addressLocation ? branch.addressLocation : "---"} dataName={branch.name} type="button-branch" text={branch.name} subtext={branch.addressLocation ? branch.addressLocation : "---"} icon="icon icon_s icon_background-auto icon_button-branch" onClick={onClick}/>
      )}</>}
    </div>
  )

}

export default ReceptionBranch;