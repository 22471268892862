import React from 'react';
import '../reception/reception-block.css';
import Done from '../../img/done.png'


function ReceptionSuccess({active, data}) {

  return (
    <div className={"reception-success"}>
      <div className="reception-success__container">
        <div className="reception-success__title">
          <div className="reception-success__img">
            <img src={Done}/>
          </div>
          Вы успешно записаны на прием
        </div>
        <div className="reception-success__time">
          {data.dateText} на {data.timeBegin != false ? (data.timeBegin.slice(0,-3) + " - " + data.timeEnd.slice(0,-3)) : "33"}
          <span>к врачу {data.doctorText}</span>
        </div>
        <div className="reception-success__info">
          <div><span>Филиал: </span>{data.name}</div>
          {data.adress != '' && data.adress != null && data.adress != undefined &&
            <div><span>Адрес: </span>{data.adress}</div>
          }
          <div><span>Услуга: </span>{data.serviceText}</div>
          <div><span>Врач: </span>{data.doctorText}</div>
          <div><span>Тип услуги: </span>{data.typeServiceText}</div>
        </div>
      </div>
    </div>
  )

}

export default ReceptionSuccess;