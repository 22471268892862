import './styles/login.css';
import '../components/forms/forms.css';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import WebApi from '../helpers/WebApi';
import Session from '../helpers/Session';
import Buttons from '../components/buttons/buttons.jsx'
import Inputs from '../components/forms/inputs.jsx'
import { useSnackbar } from '../notification/index';
import TopLoader from "react-top-loader";



function Login() {

  let config = window.config;
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);

  // Блок с ошибками
  const [errorInput, setErrorInput] = useState({});
  const [errorActive, setErrorActive] = useState({
    "error_input": false,
    "error_password": false,
  });

  // Функци валидации
  function valid(data) {
    let invalid = 0;

    let prev_error = { ...errorInput };
    if (!data.phone) {
      prev_error['phone'] = 'Вы не ввели номер телефона';
      setErrorActive(prevState => ({ ...prevState, "error_input": true, })); invalid++;
    }
    else {
      delete prev_error['phone'];
    }
    if (!data.password) {
      prev_error['password'] = 'Вы не ввели пароль';
      setErrorActive(prevState => ({ ...prevState, "error_password": true, })); invalid++;
    }
    else {
      delete prev_error['password'];
    }
    setErrorInput(prev_error);

    if (invalid > 0) return false; else return true;
  }

  // Функци авторизации
  function auth(event) {
    event.preventDefault();
    setLoader(true);

    let data = {
      "phone": event.target.querySelector('[name="phone"]').value,
      "password": event.target.querySelector('[name="password"]').value
    };

    if (!valid(data)) {
      setLoader(false);
      return false
    };

    // Запрос на авторизацию
    let web_api = new WebApi;
    web_api.auth(data, feedback);

    // Функци исполняемая после ответа от сервера
    function feedback(response) {
      setLoader(false);
      if (response == 'error') {

        enqueueSnackbar({
          message: 'Не удалось авторизироваться',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      }

      let session = new Session;
      session.createSession('token', response.data);
      window.location.reload();

    }

  }

  return (<>
    {loader &&
      <TopLoader show color="var(--primary)" thickness="4px" />
    }
    <div className="login" style={loader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none' } : { opacity: '1' }}>
      <div className="login__container">
        <div className="login__logo">
          <img src={config.logo} alt="" />
        </div>
        <div className="login__subtitle">{config.welcome_text}</div>
        <div className="login__form">
          <form onSubmit={(event) => auth(event)}>
            <div className="login__form-phone">
              <Inputs
                type="tel"
                name="phone"
                placeholder="Ваш номер телефона"
                className="input"
                iconSize="icon_s"
                iconImage="icon_input-phone"
                error={errorActive.error_input}
                errorMessage={errorInput.phone}
              />
              {/* {typeof errorInput.phone != "undefined" && <span>{errorInput.phone}</span>} */}
            </div>
            <div className="login__form-password">
              <Inputs
                type="password"
                name="password"
                placeholder="Ваш пароль"
                className="input"
                iconSize="icon_s"
                iconImage="icon_input-password"
                error={errorActive.error_password}
                errorMessage={errorInput.password}
              />
              {/* {typeof errorInput.password != "undefined" && <span>{errorInput.password}</span>} */}
            </div>
            <div className="login__form-button">
              <Buttons text="войти" type="button-primary" className="button-primary_input" icon="icon icon_s icon_button-input icon_background-auto icon_right" />
            </div>
            <div className="login__reset-password">
              <NavLink to="/reset-password">Забыли пароль?</NavLink>
            </div>
          </form>
        </div>

      </div>
    </div>
  </>)
}

export default Login;