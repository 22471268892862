import axios from 'axios';
import Session from '../helpers/Session';

export default class WebApi {

    constructor() {
        let session = new Session;
        this.token = session.getSession('token');
        this.protocol = window.location.protocol;
        this.config = window.config;
    }

    // POST запросы

    // Запрос на авторизацию

    auth(data, feedback) {

        console.log(data)

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/api/v1/PatientAuthentication/Authenticate',
            timeout: 5000,
            headers: {
                'Content-Type': 'application/json-patch+json',
                'accept': 'text/plain',
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache',
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                console.log(response)
                feedback(response);
            })
            .catch(function (error) {
                feedback(error);
            });
    }

    // Запрос на запись на прием

    sendPostSchedule(data, PostSchedule) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/PostSchedule',
            headers: {
                'Content-Type': 'text/json',
                'Authorization': 'Bearer ' + token
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                PostSchedule(true);
            })
            .catch(function (error) {
                PostSchedule('error');
            });

    }

    // Запрос на отмену записи на прием

    getCanselSchedule(data, CanselSchedule) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/api/v1/PersonalAccount/CancelSchedule',
            headers: {
                'Content-Type': 'application/json-patch+json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                CanselSchedule(true);
            })
            .catch(function (error) {
                CanselSchedule('error');
            });

    }

    // Запрос на смену пароля

    postChangePassword(data, ChangePassword) {

        let token = this.token;

        axios({
            method: 'POST',
            url: this.protocol + '//' + this.config.api + '/api/v1/PersonalAccount/ChangePassword',
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'text/json',
                'Authorization': 'Bearer ' + token
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                ChangePassword(response);
            })
            .catch(function (error) {
                ChangePassword(error.response);
            });

    }


    // GET запросы

    // Получение всех оказаных услуг пользователю

    getServicesUser(servicesValue) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetBillServices',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                servicesValue(res);
            })
            .catch(function (error) {
                servicesValue('error');
            });

    }

    // Получение документов пользователя

    getAllDocuments(DocumentsValues) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetPatientFiles',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                DocumentsValues(res);
            })
            .catch(function (error) {
                DocumentsValues('error');
            });

    }

    // Получение списка записей приёма пользователя

    getSchedules(Schedules) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetSchedules',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                Schedules(res);
            })
            .catch(function (error) {
                Schedules('error');
            });

    }

    // Получение списка услуг выбранной записи примёма

    getSchedulesServices(scheduleId, SchedulesServices) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetScheduleServices?scheduleId=' + scheduleId,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                SchedulesServices(res);
            })
            .catch(function (error) {
                SchedulesServices('error');
            });

    }

    // Получение списка типов приёма

    getAppointmentTypes(AppointmentTypes) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1/PersonalAccount/GetServiceTypes',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                AppointmentTypes(res);
            })
            .catch(function (error) {
                AppointmentTypes('error');
            });

    }

    // Получение списка филиалов

    getClinicBranches(ClinicBranches) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetClinicBranches',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                ClinicBranches(res);
            })
            .catch(function (error) {
                ClinicBranches('error');
            });

    }

    // Получение списка доступных онлайн-записей по заданному филиалу и типу записи

    getOnlineServices(params, OnlineServices) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1/PersonalAccount/GetServices',
            params: params,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                OnlineServices(res);
            })
            .catch(function (error) {
                OnlineServices('error');
            });

    }

    // Получение коллекции врачей, у которых есть свободные окна для записи по переданным параметрам

    getDoctorsFreeTime(params, DoctorsFreeTime) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetDoctorsFreeTime',
            params: params,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                DoctorsFreeTime(res);
            })
            .catch(function (error) {
                console.log(error.response.data);
                DoctorsFreeTime('error');
            });

    }

    // получение информации о пациенте

    getPatientInfo(PatientInfo) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetPatientInfo',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                PatientInfo(res);

            })
            .catch(function (error) {
                if (error.response) if (error.response.status == 401) { let session = new Session; session.removeSession('token'); window.location.reload(); }
                PatientInfo('error');
            });

    }

    // получение список файлов пациента

    getPatientFiles(PatientFiles) {
        let token = this.token;
        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetPatientFiles',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                PatientFiles(res);

            })
            .catch(function (error) {
                PatientFiles('error');
            });

    }

    // просмотр заданного файла

    getPatientFileContent(id, PatientFileContent) {
        let token = this.token;

        axios({
            encoding: null,
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetPatientFileContent?patientFileId=' + id,
            headers: {
                'Content-Type': 'text/plain',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            },
            responseType: 'arraybuffer',
            reponseEncoding: 'binary'
        })
            .then(function (response) {
                PatientFileContent(response);
            })
            .catch(function (error) {
                console.log(error)
                PatientFileContent('error');
            });

    }
    // загрузка заданного файла

    getPatientFileStream(id, GetPatientFileStream) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.protocol + '//' + this.config.api + '/api/v1.0/PersonalAccount/GetPatientFileStream?patientFileId=' + id,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token,
            },
            responseType: 'arraybuffer',
            reponseEncoding: 'binary'
        })
            .then(function (response) {
                GetPatientFileStream(response);
            })
            .catch(function (error) {
                console.log(error)
                GetPatientFileStream('error');
            });

    }

}